import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import LoadedElement from './LoadedElement';
export default class DefaultGLTFElement extends LoadedElement {
    constructor(path, onLoad) {
        super();
        this.state = {
            toRender: null
        };
        this.path = path;
        this.onLoad = onLoad;
        DefaultGLTFElement.loader.load(this.path, ({ scene: model }) => {
            let putOnScene = model;
            if (this.onLoad) {
                const ret = this.wrapOnLoad({
                    model
                }, this.onLoad);
                if (ret) {
                    putOnScene = ret;
                }
            }
            this.state.toRender = putOnScene;
            this.isLoaded = true;
        });
    }
    get() {
        return this.state.toRender;
    }
    render() {
        return [this.state.toRender];
    }
}
DefaultGLTFElement.loader = new GLTFLoader();
