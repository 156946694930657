import GameElement from "./GameElement";
export default class LoadedElement extends GameElement {
    wrapOnLoad({ model }, onLoad) {
        return onLoad({
            model,
            helpers: {
                traverseMaterials: (c) => model.traverse((o) => o.material && c(o.material))
            }
        });
    }
}
